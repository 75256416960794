@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.MenuPatrimoine-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 50px;
}

.MenuPatrimoine-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.MenuPatrimoine-block {
  font-size: 22px;
  text-transform: uppercase;
  width: 230px;
  height: 180px;
  margin: 10px;
  padding: 25px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.MenuPatrimoine-collapse {
  margin-bottom: 20px;
  width: 100%;
}

.MenuPatrimoine-collapse-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MenuPatrimoine-collapse-letter {
  border: 2px solid #ffffff;
  margin-right: 20px;
  border-radius: 50%;
  padding: 5px 11px 5px 11px;
}

.MenuPatrimoine-collapse2 {
  margin: -10px 20px 0px 20px;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px 0px 12px 12px;
}

.MenuPatrimoine-collapse-page h1 {
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 900;
  text-decoration: underline;
}

.MenuPatrimoine-collapse h2 {
  font-size: 20px;
}

.MenuPatrimoine-collapse-header {
  padding: 5px 20px 5px 40px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 22px;
  position: relative;
}

.MenuPatrimoine-collapse {
  margin-bottom: 30px;
}

.MenuPatrimoine-chevron {
  font-size: 16px;
}

.MenuPatrimoine-collapse ul {
  list-style-type: disc;
}

.MenuPatrimoine-collapse ul li {
  padding: 10px;
  cursor: pointer;
  color: #ffffff;
}

.MenuPatrimoine-collapse ul li:hover {
  text-decoration: underline;
}

.MenuPatrimoine-button-return {
  display: flex;
  width: 160px;
  margin-bottom: 20px;
  padding: 5px 9px;
  cursor: pointer;
  background-color: #032533;
  color: white;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.MenuPatrimoine-button-return:hover {
  background-color: #125674;
  transition: ease-in-out 0.2s;
  scale: 1.05;
}

/* button {
  margin-bottom: 20px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
} */
