.containerFooter {
  width: 100vw;
  padding: 0;
  margin: 0;
  background-color: #026773;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff;
}

.containerFooter h3 {
  font-weight: normal;
}

.containerBottom {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.ctnBlock {
  display: flex;
  flex-direction: column;
  border-left: 3px white solid;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
}
.ctnBody {
  display: flex;
  flex-direction: column;
}
.bodyLine {
  display: flex;
  flex-direction: row;
}
.logo {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}
.bodyText {
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.bodyTextLink {
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}
.blockTitle {
  margin-bottom: 5px;
}
.remarques {
  z-index: 5;
}

@media (max-width: 860px) {
  .containerBottom {
    justify-content: flex-start;
    margin-left: 10%;
  }
  .containerBottom svg {
    display: none;
  }
}
