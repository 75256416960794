.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.11);
  z-index: 9999;
}

.modal-main {
  position: fixed;
  background: white;
  border-radius: 10px;
  width: 95%;
  overflow-y: scroll;
  overflow-x: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.container {
  width: 100%;
  color: black;
  min-height: 75vh;
  background-color: LightGrey;
  font-family: Regular;
}
.container1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: Black;
  min-height: 75vh;
  background-color: LightGrey;
  font-family: Regular;
}
.ctnButton {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  padding-top: 30px;
}
.ctnButtonValide {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center;
  max-width: 250px;
}
.buttonCalculate {
  display: block;
  margin: auto;
  width: 30%;
  min-width: 230px;
  height: 35px;
  border-radius: 5px;
  border: none;
  background-color: #49708c;
  color: white;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  hover: #6399bf;
}

.uploadButton {
  display: block;
  min-width: 260px;
  height: 35px;
  border-radius: 5px;
  border: none;
  background-color: #49708c;
  color: white;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  hover: #6399bf;
  padding: 10px 15px 10px 15px;
}

.ctnTable {
  position: relative;
  width: 94%;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  min-width: 400px;
}
.ctnTableData {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: scroll;
}
.ctnTableDataElemLeft {
  width: 100%;
  border: grey solid 1px;
  border-collapse: collapse;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 35px;
  margin-bottom: 5px;
}
.ctnTableDataElemRight {
  width: 100%;
  border-right: grey solid 1px;
  border-top: grey solid 1px;
  border-bottom: grey solid 1px;
  border-collapse: collapse;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: 35px;
}
.ctnTableDataTxt {
  line-height: 0.5;
  margin-left: 10px;
  font-size: 16px;
}
.modal table {
  width: 40%;
  border-collapse: collapse;
  margin-right: 5px;
  border: 1px solid rgb(238, 236, 236);
  margin: auto;
}
.modal tr {
  border-collapse: collapse;
  border-radius: 5px;
  text-align: center;
}
.modal td {
  border-collapse: collapse;
  border-radius: 5px;
  padding-left: 10px;
  height: 40px;
  /* font-weight: bold; */
  text-align: left;
  border-right: 1px solid rgb(212, 212, 212);
}
.modal .td2 {
  border-collapse: collapse;
  border-radius: 5px;
  padding-right: 10px;
  height: 40px;
  font-weight: bold;
  text-align: right;
  min-width: 85px;
}
.modal th {
  border-collapse: collapse;
  border-radius: 5px;
  padding-left: 5px;
  height: 45px;
  text-align: center;
  font-style: italic;
  font-weight: normal;
}

.modal .table2 {
  width: 100%;
  border-collapse: collapse;
  margin-right: 5px;
  border: 1px solid rgb(238, 236, 236);
}
.modal thead {
  background-color: rgb(216, 211, 201);
}
.modal .td1form {
  background-color: white;
  height: 45px;
}
.modal .td1formTitle {
  background-color: white;
  height: 45px;
  text-align: left;
  padding-left: 7px;
}
.modal .td1formArt {
  background-color: white;
  height: 45px;
  font-size: 14px;
  font-weight: lighter;
}
.modal .td2form {
  background-color: rgb(245, 242, 240);
  height: 45px;
}
.modal .td2formTitle {
  background-color: rgb(245, 242, 240);
  height: 45px;
  text-align: left;
  padding-left: 7px;
}
.modal .td2formArt {
  background-color: rgb(245, 242, 240);
  height: 45px;
  font-size: 14px;
  font-weight: lighter;
}

.modalTableContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.showEmolTrue {
  width: 100%;
  height: 100%;
}

.showEmolFalse {
  width: 40%;
  height: 40%;
}

.modalPersoEmolContainer {
  flex: 10px;
  margin-left: 15px;
  font-family: "Roboto";
}

@media screen and (max-width: 800px) {
  .modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.11);
    z-index: 9999;
  }

  .modal-main {
    position: fixed;
    background: white;
    border-radius: 10px;
    width: 95%;
    overflow-y: scroll;
    overflow-x: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .container {
    width: 100%;
    color: black;
    min-height: 75vh;
    background-color: LightGrey;
    font-family: Regular;
  }
  .container1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: Black;
    min-height: 75vh;
    background-color: LightGrey;
    font-family: Regular;
  }
  .ctnButton {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .ctnButtonValide {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    max-width: 250px;
  }
  .buttonCalculate {
    display: block;
    margin: auto;
    width: 30%;
    min-width: 230px;
    height: 35px;
    border-radius: 5px;
    border: none;
    background-color: #49708c;
    color: white;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    hover: #6399bf;
  }

  .uploadButton {
    display: block;
    min-width: 260px;
    height: 35px;
    border-radius: 5px;
    border: none;
    background-color: #49708c;
    color: white;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    hover: #6399bf;
    padding: 10px 15px 10px 15px;
  }

  .ctnTable {
    width: 100%;
    min-width: 0px;
  }
  .ctnTableData {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-y: scroll;
    flex-wrap: wrap;
  }
  .ctnTableDataElemLeft {
    width: 100%;
    border: grey solid 1px;
    border-collapse: collapse;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 35px;
    margin-bottom: 5px;
  }
  .ctnTableDataElemRight {
    width: 100%;
    border-right: grey solid 1px;
    border-top: grey solid 1px;
    border-bottom: grey solid 1px;
    border-collapse: collapse;
    border-radius: 3px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 35px;
  }
  .ctnTableDataTxt {
    line-height: 0.5;
    margin-left: 10px;
    font-size: 16px;
  }
  table {
    width: 40%;
    border-collapse: collapse;
    margin-right: 5px;
    border: 1px solid rgb(238, 236, 236);
    margin: auto;
  }
  

  .table2 {
    width: 100%;
    border-collapse: collapse;
    margin-right: 5px;
    border: 1px solid rgb(238, 236, 236);
  }

  .showEmolTrue {
    width: 100%;
    height: 100%;
  }

  .showEmolFalse {
    width: 90%;
    height: 40%;
  }
  .modalPersoEmolContainer {
    margin-left: 0px;
  }
}
