.containerMain {
  padding: 0px;
  /* background-color: Colors.primaryBlue; */
  background-image: url("/src/assets/images/image_header_light.webp");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 150px;
}
.containerHeader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.containerImage {
  min-width: 150px;
  height: 100%;
  padding-left: 1%;
  align-items: center;
}
.image {
  height: 115px;
  width: 217px;
  padding-top: 19px;
}
.containerTitle {
  height: 100%;
  padding-right: 7%;
}
@media (max-width: 620px) {
  .containerTitle {
display: none;
  }
  .image {
    height: 115px;
    width: 217px;
    padding-top: 19px;
    padding-right: 50px;
  }
  /* .containerMain {
    padding: 0px;
    background-image: url("/src/assets/images/image_header.png");
    background-position: left;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
  } */
}

.overlayStyle {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 150px;
}
.title {
  font-weight: normal;
  color: white;
  font-size: 24px;
  line-height: 20px;
}
.title2 {
  color: white;
  font-size: 16px;
  font-style: italic;
  font-weight: lighter;
  line-height: 16px;
}
.containerTitles {
  margin-left: 15px;
  border-left: 1px solid white;
  padding-left: 20px;
  margin-top: 13%;
}
