.testBreadcrumb{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.home {
  margin-left: 20px;
  margin-right: 10px;
}
.urlLink {
  color: #ffffff;
  text-decoration: none;
}
.urlLink:hover {
  color: #2d8ad1;
  text-decoration: underline;
}
.urlLinkHome {
  color: #ffffff;
  text-decoration: none;
}
.urlLinkHome:hover {
  color: #2d8ad1;
  text-decoration: underline;
}

.itemProposal:hover {
  background-color: #f1f1f1;
  animation: 0.8s;
}

.itemProposal {
  animation: 0.8s;
  border-radius: 4px;
  width: 95%;
  margin-left: 2.5%;
  letter-spacing: 1px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  color: black;
}

.searchContainer {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.clear-icon:hover {
  color: #000;
}

.containerBreadcrumb {
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}

.ctnSearchBar {
  position: absolute;
  left: 500px;
  z-index: 3;
  top: 170px;
  background-color: rgb(249 249 249);
  width: 480px;
  border-radius: 5px;
}

.pictoLoupeBreadcrumb {
  width: 25px;
  position: absolute;
  left: 470px;
  top: 172px;
}

.firstItemSearch {
  text-align: left;
  line-height: 30px;
  font-weight: bold;
  color: #5e91b6;
}

.firstItemText {
  text-align: left;
  line-height: 30px;
}

.slash {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 581px) and (max-width: 1000px) {
  .containerBreadcrumb {
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 20px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
  }

  .ctnSearchBar {
    position: absolute;
    left: 90px;
    z-index: 3;
    top: 208px;
    background-color: rgb(249 249 249);
    width: 480px;
    border-radius: 5px;
  }

  .pictoLoupeBreadcrumb {
    width: 25px;
    position: absolute;
    left: 50px;
    top: 210px;
  }
}

@media (max-width: 580px) {
  .containerBreadcrumb {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #5e91b6;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    flex-wrap: wrap;
  }

  .ctnSearchBar {
    position: absolute;
    left: 90px;
    z-index: 3;
    top: 208px;
    background-color: rgb(249 249 249);
    width: 300px;
    border-radius: 5px;
  }

  .pictoLoupeBreadcrumb {
    width: 25px;
    position: absolute;
    left: 50px;
    top: 210px;
  }

  .itemProposal {
    animation: 0.8s;
    border-radius: 4px;
    width: 95%;
    margin-left: 2.5%;
    letter-spacing: 1px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    color: black;
    height: 30px;
    overflow: hidden;
  }

  .firstItemSearch {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    line-height: 30px;
    font-weight: bold;
    color: #5e91b6;
    overflow: hidden;
    width: 145px;
    height: 30px;
  }

  .firstItemText {
    text-align: left;
    line-height: 30px;
    margin-left: 15px;
  }

  .slash {
    margin-left: 4px;
    margin-right: 4px;
  }
}
