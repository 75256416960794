@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.barHome {
  background-color: #5e91b6;
  width: 2px;
  height: 30px;
}
.containerHome {
  width: 100%;
  color: #222529;
  min-height: 68vh;
}

.containerHome h2 {
  font-weight: normal;
  margin-right: 20px;
}

.containerCardsHome {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  border-radius: 25px;
  padding: 30px 0px;
  gap: 30px;
  margin-bottom: 20px;
}

.containerItemHome {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  line-height: 1.5;
  align-items: center;
}
.cardHome {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: white;
  margin-left: -30px;
  margin-right: -30px;
  border-radius: 25px;
  width: 50%;
  border: 1px solid #041f2b;
}

.home-global-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
}

.starCardHome {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.cardHeaderHome {
  position: relative;
  background-color: #e8e6e9;
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.cardHeaderBlueHome {
  background-color: #5e91b6;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 3fr;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.logoHome {
  margin: 0px 20px;
}
.listElemHome {
  line-height: 1.8;
  cursor: pointer;
}
.listElemHomeNew {
  display: flex;
  align-items: center;
  color: #9f5c4a;
  font-weight: bold;
  gap: 5px;
  margin-top: 7px;
  cursor: pointer;
}
.listElemStarHome {
  line-height: 1.8;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.liStarHome {
  margin-right: 10px;
  margin-top: 3px;
}
.cardBodyHome {
  max-height: 180px;
  padding-right: 15px;
}
.cardTitleHome {
  text-transform: uppercase;
  color: #041f2b;
  text-align: center;
}
.cardTitleWhiteHome {
  font-weight: normal;
  color: white;
  margin-left: 15px;
}
.homeMessageLogo {
  position: fixed;
  right: 15px;
  bottom: 45px;
  cursor: pointer;
}

.containerTarifDesNotaires {
  display: flex;
  flex-direction: row;
  background-color: #005444;
  width: 80%;
  margin: auto;
  border-radius: 25px;
  padding: 30px;
  gap: 30px;
  margin-bottom: 50px;
  color: #ffffff;
}

.tarifDesNotairesColumn1 {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 15px;
  margin-right: 30px;
}

.tarifDesNotairesColumn2 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.tarifDesNotairesTitle {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 24px;
  cursor: pointer;
}

.tarifDesNotairesButton {
  display: flex;
  border: 1px solid white;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  width: fit-content;
  font-size: 18px;
  gap: 10px;
  cursor: pointer;
}

.tarifDesNotairesButton:hover {
  background-color: #ffffff;
  color: #005444;
}

.tarifDesNotairesOnglet {
  background-color: #062d26;
  font-size: 20px;
  padding: 15px 25px 15px 25px;
  border-radius: 0px 30px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tarifDesNotairesColumn21 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tarifDesNotairesColumn22 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tarifDesNotairesTxt {
  text-align: justify;
}

.containerAnalysePat {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #026773;
  width: 80%;
  margin: auto;
  border-radius: 25px;
  padding: 30px;
  gap: 30px;
  margin-bottom: 50px;
  color: #ffffff;
  min-height: 180px;
}
.analysePatButton {
  display: flex;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  width: fit-content;
  font-size: 18px;
  gap: 10px;
  cursor: pointer;
}

.analysePatButton:hover {
  background-color: #ffffff;
  color: #026773;
}

.analysePatOnglet {
  background-color: #024959;
  font-size: 20px;
  padding: 15px 25px 15px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  text-align: center;
}

.analysePatColumn2 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.analysePatColumn2::before,
.analysePatColumn2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 25%;
  width: 2px;
  background-color: #ffffff;
  margin: -30px;
}

.analysePatColumn2::before {
  height: auto;
  bottom: 0;
}

.analysePatColumn2::after {
  transform-origin: top left;
  transform: skew(35deg);
  height: auto;
}

.containerPratiquesHome {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #deb79a;
  width: 80%;
  margin: auto;
  border-radius: 25px;
  padding: 30px;
  gap: 30px;
  margin-bottom: 100px;
  color: #041f2b;
  align-items: center;
}

.pratiquesHomeButton {
  display: flex;
  border: 1px solid #041f2b;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  width: fit-content;
  font-size: 18px;
  gap: 10px;
  cursor: pointer;
}

.pratiquesHomeButton:hover {
  background-color: #041f2b;
  color: #deb79a;
}

.pratiquesHomeColumn2 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 70%;
}

@media (max-width: 1050px) {
  .cardHome {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .cardHeaderHome {
    justify-content: center;
  }

  .logoHome {
    position: relative;
    left: 0px;
    margin-left: 15px;
  }

  .containerTarifDesNotaires {
    width: 80%;
    flex-wrap: wrap;
  }

  .tarifDesNotairesColumn1 {
    width: 100%;
    gap: 15px;
    margin-right: 0px;
  }

  .tarifDesNotairesColumn21 {
    gap: 20px;
  }

  .containerAnalysePat {
    flex-wrap: wrap;
  }

  .analysePatColumn2 {
    margin-top: 10px;
  }

  .containerPratiquesHome {
    flex-wrap: wrap;
  }

  .pratiquesHomeColumn2 {
    width: 100%;
  }

  .containerHome h2 {
    font-weight: normal;
    font-size: 20px;
}
}
