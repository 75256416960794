/* .containerTitle {
  color: #5e91b6;
  width: 90%;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.containerBody {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
} */
.containerSimulateurTitle {
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
}
.containerItemSimulateurTitle {
  display: grid;
  grid-template-columns: 32px 12px 90%;
  margin-bottom: 35px;
  padding-top: 55px;
  font-size: 22px;
  line-height: 1.5;
}
.barSimulateurTitle {
  background-color: #5e91b6;
  width: 3px;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.containerStarSimulateurTitle {
  margin-top: auto;
  margin-bottom: auto;
}
.container {
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 700px) {
  .containerSimulateurTitle {
    width: 100vw;
  }
  .containerItemSimulateurTitle {
    display: grid;
    grid-template-columns: 32px 12px 80%;
    margin-bottom: 35px;
    padding-top: 30px;
    font-size: 22px;
    line-height: 1.5;
  }
  .bodyTextSimulateurTitle {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 18px;
  }
}
