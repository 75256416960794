.lateralMenu-container {
  display: flex;
  align-self: baseline;
  height: auto;
  min-height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.lateralMenu-container svg {
  cursor: pointer;
}

.lateralMenu {
  display: flex;
  flex-direction: column;
  background: #012e40;
  color: white;
  transition: width 0.2s;
  height: 100vh;
  overflow-y: scroll;
}
.listElemStarHome {
  margin-top: 0px;
}
.lateralMenu-collapse-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  background: #34495e;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  z-index: 10;
}

.lateralMenu-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lateralMenu-resizer {
  width: 10px;
  cursor: ew-resize;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.burger-menu {
  display: none;
  position: fixed;
  height: 60px;
  width: 50px;
  top: 0px;
  left: 0px;
  cursor: pointer;
  padding: 10px;
  background: #012e40;
  color: white;
  font-size: 45px;
  border-radius: 0px 0px 7px 0px;
  z-index: 25;
}

.collapsed {
  display: none;
}

.closed {
  display: none;
}

.itemProposal:hover {
  background-color: #f1f1f1;
  animation: 0.8s;
}

.itemProposal {
  animation: 0.8s;
  border-radius: 4px;
  width: 95%;
  margin-left: 2.5%;
  letter-spacing: 1px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  color: black;
  overflow: hidden;
}

.lateralMenu-searchContainer {
  position: relative;
}

.lateralMenu-clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.lateralMenu-clear-icon:hover {
  color: #000;
}

.lateralMenu-containerBreadcrumb {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 7px;
  color: #5e91b6;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  flex-wrap: wrap;
}

.lateralMenu-ctnSearchBar {
  top: 30px;
  display: flex;
  position: relative;
  min-width: 100px;
  width: 90%;
  border-radius: 5px;
  overflow: clip;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 70px;
}

.lateralMenu-pictoLoupeBreadcrumb {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  position: relative;
}

.firstItemSearch {
  text-align: right;
  line-height: 30px;
  font-weight: bold;
  color: #5e91b6;
}

.firstItemText {
  text-align: left;
  line-height: 30px;
}

.slash {
  margin-left: 10px;
  margin-right: 10px;
}

.lateralMenu-ctnSearchBarBorder {
  width: 100%;
  border: #5e91b6 1px solid;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 1px;
  background-color: #ffffff;
  overflow: hidden;
}

.lateralMenu-searchBar {
  border-radius: 5px;
  height: 25px;
  outline: none;
  border: none;
  background-color: white;
  padding-left: 10px;
  width: 170px;
}
.lateralMenu-ctnSearchBarProposes {
  position: absolute;
  text-align: right;
  background-color: white;
  width: 700px;
  z-index: 50;
  margin-top: 50px;
  height: auto;
  max-height: 450px;
  border: 1px solid #012e40;
  border-radius: 10px;
  box-shadow: 3px 2px 5px 3px #0000005e;
  overflow: scroll;
  padding: 15px 0px 15px 0px;
}

.show-container {
  display: block;
}

.lateralMenu-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lateralMenu-bullet {
  padding: 0px 0px 0px 15px;
}

.txtDisconnect {
  text-decoration: underline;
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}
.txtAccount {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
}

.collapsibleCardToggleIcon-sup {
  font-size: 13px;
  position: absolute;
  text-align: end;
  right: 0px;
  margin-right: 0px;
}

/* collapse Menu */

.collapsibleCard {
  margin-top: 0px;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.collapsibleCardContact {
  margin-top: 50px;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.collapsibleCardHeader {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-transform: uppercase;
}

.collapsibleCardHeaderRemarque {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-transform: uppercase;
}

.collapsibleCardIcon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.collapsibleCardTitle {
  flex-grow: 1;
  font-size: 16px;
  text-transform: uppercase;
}
.collapsibleCardHeader:hover {
  border-bottom: 1px solid white;
}

.collapsibleCardToggleIcon {
  font-size: 13px;
}

.collapsibleCardBody {
  padding: 15px 0px 0px 0px;
}

.collapsibleCard:last-of-type {
  margin-bottom: 200px;
}

.collapsibleCardFlex {
  display: flex;
  align-items: center;
}

@media (max-width: 860px) {
  .burger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lateralMenu-container {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 20;
  }

  .lateralMenu-container.open {
    transform: translateX(0);
  }

  .lateralMenu {
    width: 100%;
    padding-top: 90px;
  }

  .lateralMenu .resizer {
    display: none;
  }

  .lateralMenu-content {
    padding: 0px 20px 20px 20px;
  }

  .lateralMenu-collapse-icon {
    display: none;
  }

  .lateralMenu-ctnSearchBarProposes{
    width: 120%;
    max-height: 450px;
  }
}
@media (min-width: 581px) and (max-width: 1000px) {
  /* .lateralMenu-containerBreadcrumb {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 40px;
    color: #5e91b6;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    flex-wrap: wrap;
  }

  .lateralMenu-ctnSearchBar {
    position: absolute;
    left: 90px;
    z-index: 3;
    top: 208px;
    width: 480px;
    border-radius: 5px;
  }

  .pictoLoupeBreadcrumb {
    width: 25px;
    position: absolute;
    left: 50px;
    top: 210px;
  } */
}

@media (max-width: 580px) {
  /* .lateralMenu-containerBreadcrumb {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 40px;
    color: #5e91b6;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    flex-wrap: wrap;
  }

  .lateralMenu-ctnSearchBar {
    position: absolute;
    left: 90px;
    z-index: 3;
    top: 208px;
    background-color: rgb(249 249 249);
    width: 300px;
    border-radius: 5px;
  }

  .pictoLoupeBreadcrumb {
    width: 25px;
    position: absolute;
    left: 50px;
    top: 210px;
  }

  .itemProposal {
    animation: 0.8s;
    border-radius: 4px;
    width: 95%;
    margin-left: 2.5%;
    letter-spacing: 1px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    color: black;
    height: 30px;
    overflow: hidden;
  }

  .firstItemSearch {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    line-height: 30px;
    font-weight: bold;
    color: #5e91b6;
    overflow: hidden;
    width: 145px;
    height: 30px;
  }

  .firstItemText {
    text-align: left;
    line-height: 30px;
    margin-left: 15px;
  }

  .slash {
    margin-left: 4px;
    margin-right: 4px;
  } */
}
