
.cardSepteoSpecial {
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  background-color: white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  min-width: 400px;
  width: 90%;
  border: 1px solid #082a42;
}
.buttonSepteoMemo {
  padding: 10px 15px 10px 15px;
  margin-top: 20px;
  border: none;
  background-color: #5e91b6;
  color: white;
  border-radius: 11px;
  box-shadow: 1px 1px 3px 1px #0000003b;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}


.cardTable {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
  max-height: 220px;
  padding: 10px;
}

.tableContainer {
  padding-bottom: 5px;
  background-color: white;
  box-shadow: inset -2px 2px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  width: 90%;
  overflow-x: auto;
  max-height: 200px;
}

.tableSizing {
  width: 100%;
}

.tableHeaders {
  color: grey;
  display: block;
}

.rowSizing {
  display: flex;
  justify-content: space-around;
}

.cellBody {
  overflow-y: auto;
  max-height: 150px;
  display: block;
}

.cellSpacing1 {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  align-content: center;
  width: 10%;
}

.cellSpacing2 {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  align-content: center;
  width: 25%;
}

.cellSpacing3 {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  align-content: center;
  width: 25%;
}

.cellSpacing4 {
  margin-left: 1;
  text-align: center;
  cursor: pointer;
  align-content: center;
  width: 47%;
}

.cardFolders {
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
  padding: 10px;
}

.selectedRow {
  color: primaryBlue;
}

.cardFlex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid rgb(157 114 169);
  border-radius: 0px 0px 4px 4px;
  background-color: white;
  margin-bottom: 20px;
}
.cardFlexSuccession {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #5e91b6;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 20px;
}
.cardRowGenapi {
  display: grid;
  width: 90%;
  margin-left: 5%;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.cardRowSepteoMessage {
  width: 90%;
  margin-left: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cardRowChild {
  border-radius: 4px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 98%;
  margin-left: 1%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cardElemVerticalyCenter {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.messageInABottle {
  width: 100%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: red;
}

.ctnButton {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  padding-top: 30px;
}
.buttonCalculate {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: none;
  background-color: buttonGreen;
  color: white;
}

.buttonsArea {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.addButton {
  width: 20%;
  min-width: 80px;
  margin: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  display: block;
  border: solid 1px #5e91b6;
  border-radius: 5px;
  background-color: #5e91b6;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.removeButton {
  width: 20%;
  min-width: 80px;
  margin: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  border: solid 1px #5e91b6;
  border-radius: 5px;
  background-color: white;
  color: #5e91b6;
  font-weight: bold;
  cursor: pointer;
}

.elemHeight {
  padding-left: 10px;
  height: 35px;
  border: 1px solid lightGrey;
  border-radius: 5px;
  max-width: 350px;
  min-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.globalCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.dateField {
  text-align: center;
  color: #5e91b6;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 7px;
  width: 100%;
  max-width: 350px;
  min-width: 200px;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}

.cardTitle {
  display: grid;
  width: 90%;
  margin-left: 5%;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 19px;
  color: rgb(94, 145, 182);
}
.septeoTitle {
  display: flex;
  gap: 20px;
  width: 90%;
  margin-left: 5%;
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 19px;
  font-weight: bold;
  color: #082a42;
}
.messageBoxInfoDmtg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.messageInfoDmtg {
  text-align: center;
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  font-size: 20px;
  border: 1px solid #01035a;
  border-radius: 7px;
  color: #01035a;
  width: fit-content;
}

@media screen and (max-width: 900px) {
    .cardSepteoSpecial {
        min-width: 0px;
      }
    
}