@font-face {
  font-family: Roboto;
  src: url("../../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("../../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* global */
.essentiel_container {
  display: flex;
  flex-direction: row;
}

.essentiel {
  width: 100%;
  background-color: #ffffff;
}

@media (max-width: 1080px) {
  .essentiel_container {
    display: flex;
    flex-direction: column;
  }
}

.fisca_intro_header {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px auto 10px auto;
  gap: 10px;
  border: 1px solid #016954;
  padding: 10px 30px;
  background-color: #ffffff;
  border-radius: 15px;
}

.fisca_intro_global {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2%;
  padding-right: 4%;
  background-color: #44884862;
}

.fisca_intro_global button {
  background-color: #ffffff;
  color: #306a36;
  border: none;
  border-radius: 10px;
  padding: 7px 10px 7px 10px;
  cursor: pointer;
  font-weight: bold;
}

.fisca_intro_global button:hover {
  background-color: #306a36;
  color: #ffffff;
}

.fisca_intro_title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 900;
  color: #016954;
  margin-top: 10px;
  font-size: 21px;
}

.fisca_intro_green_card {
  padding: 10px;
  border: 2px solid #016954;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fisca_intro_green_card p {
  text-align: justify;
}

.fisca_intro_tableau {
  border: 2px solid #b09d7e;
  width: 100%;
  padding: 20px;
  border-collapse: collapse;
}

.fisca_intro_tableau th {
  font-weight: normal;
  text-align: start;
  padding: 10px;
  text-align: justify;
}

.fisca_intro_tableau tbody {
  font-weight: normal;
  text-align: start;
  padding: 10px;
  text-align: justify;
}

.fisca_intro_tableau td {
  font-weight: normal;
  text-align: start;
  padding: 10px;
  text-align: justify;
}

.fisca_intro_tableau b {
  font-weight: bold;
  text-decoration: underline;
}

.fisca_intro_tableau_variante {
  background-color: #b09d7e6c;
}

.fisca_intro_tableau_variante1 {
  border-left: 1px solid #b09d7e;
}

.fisca_intro_tableau_variante2 {
  background-color: #b09d7e6c;
  display: flex;
  padding: 10px;
}

.fisca_intro_tableau_variante3 {
  display: flex;
  padding: 10px;
}

.fisca_intro_tableau_variante4 {
  display: flex;
  padding: 10px;
  border: 2px solid #b09d7e;
  text-align: justify;
  margin-top: 20px;
  border-collapse: collapse;
}

.fisca_intro_tableau_variante5 {
  display: flex;
  border: 2px solid #b09d7e;
  text-align: justify;
  margin-top: 20px;
  border-collapse: collapse;
}

/* ONGLETS */
body {
  font-family: Roboto;
}

.li_essentiel {
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 35px;
  margin: 0 1px;
  border-radius: 0px 10px 0px 0px;
  text-transform: uppercase;
  transition: transform 0.3s ease, z-index 0.3s ease;
  cursor: pointer;
}

.li_essentiel a {
  text-decoration: none;
  color: white;
  display: block;
  transition: background-color 0.3s ease;
}

.li_essentiel:hover {
  transform: scale(1.1);
  z-index: 6;
  text-decoration: underline;
  color: white;
  opacity: 1;
}

.link-actes {
  background-color: #005444;
  z-index: 2;
}

.link-form {
  background-color: #306a36;
  z-index: 2;
}

.link-fiscalite {
  background-color: #448849;
  z-index: 2;
}

.link-fiches {
  background-color: #7d931d;
  z-index: 2;
}

.link-fixes {
  background-color: #65975d;
  z-index: 2;
}

.onglets_essentiel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 20px;
  border-bottom: 1px solid #005443;
  margin-top: 20px;
  width: 100%;
}

.inactive {
  opacity: 0.5;
}

.ul_essentiel {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 1080px) {
  .ul_essentiel {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
  }
  .onglets_essentiel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0px;
    border-bottom: 1px solid #005443;
    background-color: #f8f9fa;
    margin-top: 20px;
    width: 100%;
  }
}

/* MENU_ESSENTIEL */
.alphabet-column {
  display: flex;
  width: 60px;
  overflow-y: auto;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  /* max-height: 100vh; */
  height: 100vh;
  margin-bottom: 60px;
}

.group-letter h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 15px 5px 5px; /* Ajuste les marges selon tes besoins */
  color: #016954; /* Tu peux personnaliser la couleur ici */
}

.alphabet-letter {
  font-family: Roboto, sans-serif;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #005443;
  border-radius: 12px;
  margin: 10px 0px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #043c31;
  font-weight: bold;
  position: relative;
  background-color: white;
}

.alphabet-letter.active {
  background-color: #026954;
  color: white;
}

.alphabet-letter:hover {
  background-color: #026954;
  color: white;
}

.letter-container {
  display: flex;
  align-items: center;
  position: relative;
}

.letter-separator {
  width: 8px;
  margin: 1px 0;
  border: 1px solid #005443;
  transform: rotate(90deg);
}

.menu-column {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
}

.menu-item {
  padding: 10px;
  position: relative;
  cursor: pointer;
  text-align: justify;
  margin: 5px 0px 5px 0px;
  font-family: roboto;
}

.menu-item::before {
  content: "";
  display: inline-block;
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
}

.menu-item:hover {
  color: #026954;
}

.menu-item.active::before {
  background-color: #026954;
}

.menu-item.active {
  color: #026954;
  border: 1px solid #043c31;
  border-radius: 7px;
  background-color: white;
}

.menu-item:hover::before {
  background-color: #026954;
}

.sidebar-container {
  display: flex;
  height: 100vh;
  overflow: scroll;
  width: 45%;
  border-right: 1px solid #b09d7e;
  /* border-top: 1px solid #B09D7E; */
  padding-bottom: 60px;
  padding-top: 40px;
  background-color: #b09d7e25;
}

@media (max-width: 1080px) {
  .sidebar-container {
    display: flex;
    height: 150px;
    overflow: hidden;
    width: 100%;
    border-right: 1px solid #b09d7e;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #b09d7e25;
  }
}

/* EMOL composant */
.emol_global_card {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  border: 1px solid #b09d7e;
  width: 50%;
  border-radius: 8px;
  margin-bottom: 50px;
  background-color: white;
  height: fit-content;
  padding-bottom: 20px;
  position: sticky;
  top: 20px;
}
.droits_fixes_intro {
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  width: 90%;
  text-align: justify;
}
.droits_fixes_card {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  border: 1px solid #b09d7e;
  width: 90%;
  border-radius: 8px;
  margin-bottom: 50px;
  background-color: white;
  height: fit-content;
  padding-bottom: 20px;
}

.emol_global_title {
  padding: 15px 30px 15px 30px;
  background-color: #b19e7e79;
  border-radius: 8px 8px 0px 0px;
  color: #043c31;
  text-align: center;
  font-family: roboto;
}

.emol_prop_container ul {
  margin-block-start: 5px;
  margin-block-end: 5px;
}

/* emol_prop component */
.emol_fix_tbl {
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.emol_prop_button {
  background-color: #016954;
  color: white;
  padding: 10px 30px 10px 30px;
  width: fit-content;
  width: auto;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.emol_prop_button:hover {
  background-color: #043c31;
}

.emol_prop_calcul {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  gap: 20px;
  width: 90%;
  margin-top: 20px;
}

.emol_prop_card {
  margin-top: 30px;
  margin-bottom: 40px;
}

.emol_prop_description {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: justify;
}

.droits_fixes_description ul {
  margin-right: 10px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: justify;
  list-style-type: disc;
}

.emol_prop_input {
  border: 1px solid #b09d7e;
  text-align: center;
  color: #043c31;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  width: 80%;
  min-width: 0px;
  max-width: 900px;
  font-size: 14px;
}

.emol_prop_result {
  display: flex;
  margin: 20px auto 20px auto;
  align-items: center;
  gap: 20px;
  width: 87%;
  border: 1px solid #b09d7e;
  padding: 10px;
  border-radius: 7px;
}

.emol_prop_text {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  height: auto;
  border: 1px solid #043c31;
  padding: 20px 5px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.emol_prop_tab {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  border: 2px solid #b09d7e;
  height: auto;
  margin-top: 20px;
}

.emol_prop_title {
  background-color: #016954;
  color: white;
  width: 100%;
  padding: 10px 0px 10px 0px;
  text-align: center;
}

.emol_prop_table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  letter-spacing: 1px;
}

.emol_prop_thead {
  /* background-color: #b09d7e48; */
  font-family: Roboto, sans-serif;
  border-bottom: 1px solid #b09d7e;
  font-weight: bolder;
}

.emol_prop_th {
  border-right: 1px solid #b09d7e;
  padding: 8px 10px;
  text-align: center;
}

.emol_prop_th:nth-child(3n) {
  border-right: none;
}

.droits_fixes_th:nth-child(4n) {
  border-right: none;
}

.emol_prop_td {
  border-right: 1px solid #b09d7e;
  padding: 8px 10px;
  text-align: center;
}

.droits_fixes_th {
  border-right: 1px solid #b09d7e;
  padding: 8px 10px;
  text-align: center;
}

.droits_fixes_td {
  border-right: 1px solid #b09d7e;
  padding: 8px 10px;
  text-align: center;
}

.droits_fixes_td:nth-child(4n) {
  border-right: none;
  padding: 8px 10px;
  text-align: right;
}
.no-border-right {
  border-right: none !important;
}

.fisca_td {
  border-right: 1px solid #b09d7e;
  padding: 8px 10px;
  text-align: center;
}

.emol_fisca_thead {
  font-family: Roboto, sans-serif;
  border-bottom: 1px solid #b09d7e;
  font-weight: bolder;
  background-color: #b19e7e79;
}

@media (max-width: 1080px) {
  .emol_prop_calcul {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    gap: 20px;
    width: 90%;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .emol_prop_input {
    border: 1px solid #b09d7e;
    text-align: center;
    color: #043c31;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 7px;
    width: 100%;
    min-width: 0px;
    max-width: 900px;
    font-size: 14px;
  }

  .emol_global_card {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    border: 1px solid #b09d7e;
    min-width: 40%;
    max-width: 90%;
    border-radius: 8px;
    margin-bottom: 50px;
    background-color: white;
    padding-bottom: 20px;
    width: 90%;
  }
}

/* EMOL DE FORMALITÉS */
.container_global_emol_form {
  position: relative;
  margin: 0px;
}

.container_emol_form {
  display: flex;
  width: 75%;
  margin: 20px 40px 60px 40px;
  flex-direction: column;
  align-items: flex-start;
}
.input_emolform {
  display: flex;
  width: 70%;
  padding: 5px;
  border: 0.6px solid #000000;
  border-radius: 10px;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tr_emolform {
  text-align: center;
}
.td_emolform {
  border-collapse: collapse;
  padding-left: 10px;
  height: 40px;
  font-weight: bold;
  text-align: left;
  border-right: 1px solid rgb(212, 212, 212);
}
.th_emolform1 {
  border-collapse: collapse;
  padding: 7px;
  height: 45px;
  text-align: center;
  width: 50%;
}
.th_emolform2 {
  border-collapse: collapse;
  padding: 7px;
  height: 45px;
  text-align: center;
  width: 11%;
}
.th_emolform3 {
  border-collapse: collapse;
  padding: 7px;
  height: 45px;
  text-align: center;
  width: 11%;
}
.th_emolform4 {
  border-collapse: collapse;
  padding: 7px;
  height: 45px;
  text-align: center;
  width: 11%;
}
.th_emolform5 {
  border-collapse: collapse;
  padding: 7px;
  height: 45px;
  text-align: center;
  width: 11%;
}
.table2_emolform {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #043c31;
  border-radius: 15px;
}
.thead_emolform {
  background-color: #b09d7eaa;
}
.td1form_emolform {
  height: 45px;
}
.td1formTitle_emolform {
  height: 45px;
  text-align: left;
  padding: 7px;
  text-align: justify;
}
.td1formArt_emolform {
  height: 45px;
  font-size: 14px;
  font-weight: lighter;
}
.td2form_emolform {
  height: 45px;
}
.td2formTitle_emolform {
  height: 45px;
  text-align: left;
  padding: 7px;
}
.td2formArt_emolform {
  height: 45px;
  font-size: 14px;
  font-weight: lighter;
}
.total_emolform {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 30px;
  bottom: auto;
  text-align: center;
  background-color: #b09d7eaa;
  border-radius: 7px;
  padding: 10px;
  width: 10%;
  color: #043c31;
}

.total_nb_emolform {
  color: #043c31;
  padding: 7px;
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  .table2_emolform {
    font-size: 14px;
  }

  .container_emol_form {
    display: flex;
    width: 100%;
    margin: 20px auto 60px auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .total_emolform {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 20px;
    bottom: auto;
    text-align: center;
    background-color: #b09d7e;
    border-radius: 7px;
    padding: 5px;
    width: 40%;
  }

  .total_nb_emolform {
    padding: 5px;
    font-size: 17px;
  }

  .td1formArt_emolform {
    font-size: 12px;
  }

  .td1form_emolform {
    font-size: 12px;
  }

  .td1formTitle_emolform {
    text-align: start;
  }
}

.fisca_loupe {
  margin: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 10px;
  text-align: center;
}

.fisca_loupe svg {
  cursor: pointer;
}

/* modal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 900px;
  max-height: 80%;
  overflow-y: auto;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: justify;
}

.modal-content-pratiques {
  background: white;
  padding: 30px;
  border-radius: 10px;
  /* width: 85%; */
  /* max-height: 80%; */
  overflow-x: scroll;
  overflow-y: scroll;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #034034;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
}
.zoom-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #ffffff;
}

.zoom-button {
  background-color: #005444;
  color: white;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 10px;
}

.zoom-button:hover {
  background-color: #031d18;
}

.zoom-controls span {
  font-size: 1rem;
  font-weight: bold;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  background-color: #005444;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50px;
  margin-left: auto;
}

.close-btn-pratiques {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  background-color: #005444;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50px;
  margin-left: auto;
  z-index: 80;
}

.close-btn:hover {
  background-color: #031d18;
  color: white;
  transform: scale(1.05);
}

.close-btn-pratiques:hover {
  background-color: #031d18;
  color: white;
  transform: scale(1.05);
}

.modal-pdf .react-pdf__Page__canvas {
  width: auto !important;
  height: 85vh !important;
}

.modal-content h1 {
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}

.modal-content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.modal-content ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.6;
}

.modal-content ul li::before {
  content: •;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2rem;
  color: #333;
}

.modal-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9rem;
}

.modal-content table th,
.modal-content table td {
  border: 1px solid #b09d7e;
  padding: 10px;
  text-align: left;
}

.modal-content table th {
  background-color: #b09d7e;
  font-weight: bold;
}

.modal-content table td {
  background-color: #fff;
}

.modal-content table thead th {
  background-color: #b19e7e79;
}

.modal-content table td strong {
  font-weight: bold;
}

/* modal fisca intro */
.modalFiscaIntro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 19;
}

.modalFiscaIntro-content {
  position: relative; /* L'élément parent devient le contexte de référence pour le bouton */
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: auto;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 20;
}

.modalFiscaIntro-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky; /* Change fixed en absolute */
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  background-color: #005444;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50px;
  margin-left: auto;
  margin-bottom: 20px;
}

.modalFiscaIntro-close-button:hover {
  background-color: #031d18;
  color: white;
  transform: scale(1.05);
}

/* Onglet sur les débours */

.debours-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  margin: auto;
  width: 90%;
}

.debours-container h4 {
  font-size: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #7d931d;
}

.debours-container h5 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.debours-container h6 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #666;
}

.debours-container p {
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
}

.debours-section {
  margin-top: 20px;
  margin-bottom: 40px;
}

.debours-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.debours-container .debours-tbody tr {
  border-top: 1px solid #b09d7e;
  padding: 20px;
}

.debours-container table,
.debours-container th,
.debours-container td {
  border: 1px solid #b09d7e;
}

.debours-container th,
.debours-container td {
  padding: 10px;
  text-align: justify;
}

.debours-container th {
  background-color: #b19e7e79;
}

.debours-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.note {
  font-size: 12px;
  margin-top: 20px;
}
.debours-section-td {
  font-weight: bold;
  width: 17%;
}

/* FICHES PRATIQUES */
.pdf-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0px;
  padding: 20px;
  margin-left: 40px;
}

.pdf-card {
  position: relative;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.2s ease;
  margin-left: -30px;
  z-index: 1;
  border: 2px solid #005444;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ajuste en fonction de la taille maximale de ta carte */
  max-width: 400px; /* Ajuste selon la taille souhaitée pour le zoom */
}

/* .pdf-card:nth-child(3n + 1) {
  margin-left: 0;
} */

.pdf-card:hover {
  transform: scale(1.05);
  z-index: 10;
}

.pdf-thumbnail-container {
  position: relative;
  width: 100%;
  height: 300px;
}

.pdf-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.622);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

.pdf-overlay:hover {
  background-color: rgba(18, 52, 23, 0.928);
  color: #ffffff;
}

.pdf-title {
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
  padding: 0px 20px;
}

.pdf-actions {
  display: flex;
  gap: 10px;
}

.view-btn,
.download-btn {
  padding: 10px 20px;
  border: none;
  background-color: #043c31;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.view-btn:hover,
.download-btn:hover {
  background-color: #031d18;
  color: #ffffff;
}

.pdf-list-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: normal;
  color: #082a42;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pdf-list-block {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  min-width: 570px;
  border-radius: 7px;
  padding: 20px 0px;
  width: 80%;
  margin-bottom: 20px;
}

.pdf-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 10px;
  padding: 10px 0px;
  width: 100%;
}

.centered-cell {
  vertical-align: middle;
}

@media screen and (max-width: 900px) {
  .pdf-list-block {
    width: 90%;
    min-width: 0px;
  }
}

.fisca_intro_green_card li {
  list-style: inside;
}

.fisca_info {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  margin-top: 20px;
}
