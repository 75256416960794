.info-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.info-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70%;
  width: 70%;
  transform: translate(-50%, -50%);
  background-color: #082a42;
  border: 1px solid #ffffff;
  padding: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  flex-direction: column;
}
.buttonPopUpUpload{
  margin-top: 10px;
  padding: 14px 18px 14px 18px;
  font-size: 20px;
  border-radius: 18px;
  box-shadow: 3px 3px 3px 3px #1b1b1b4a;
  cursor: pointer;
  background-color: #5e91b6;
  color: white;
  border: none;
}
.info-popup-main-title {
  font-size: 40px;
  padding: 10px;
  color: #56ace2;
  margin: 30px auto; 
  text-align: center;
  text-transform: uppercase;
}

.info-popup-main-text{
  width: 90%; 
  margin: 30px auto; 
  font-size: 34px
}

@media screen and (max-width: 1000px) {
  .info-popup-main-title {
    font-size: 30px;
    margin: 15px auto; 
  }
  
  .info-popup-main-text{
    margin: 15px auto; 
    font-size: 24px
  }
  
}

.info-popup-second-title{
  font-size: 24px;
  padding-bottom: 20px;
  color: #ffffff;
  text-transform: uppercase;
}

.info-titlePopUp {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.info-popup-textslide{
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: #b1bec7;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.info-popup-content {
  text-align: center;
  color: #ffffff;
  font-size: 22px;
}

.info-carousel-controls {
  position: absolute;
  top: calc(50% - 20px);
  width: 100%;
}

.info-prev-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid;
  background-color: #0a354f;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  left: 20px;
}
.info-next-button {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid;
  background-color: #0a354f;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.info-slide-indicator {
  padding: 10px;
  font-size: 18px;
  color: #ffffff;
}

.info-special-slide-1 {
  max-width: 100%;
  max-height: 450px;
  margin: 0 auto;
  display: block;
  border-radius: 7px;
}

.info-special-slide-2 {
  max-width: 100%;
  max-height: 450px;
  margin: 0 auto;
  display: block;
  border-radius: 7px;
  border: 4px solid rgb(255, 255, 255);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.info-textPopUp {
  padding-top: 0px;
  padding-bottom: 20px;
  font-size: 23px;
  font-weight: bold;
  color: #56ace2;
}
.videoPopUp{
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.video-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70%;
  width: 70%;
  transform: translate(-50%, -50%);
  background-color: #082a42;
  border: 1px solid #ffffff;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  flex-direction: column;
}

.info-popup-cta-button {
  background-color: #56ace2;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  color: #0a254b;
  font-size: 18px;  
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
}
.info-popup-cta-button:hover {
  background-color: #286d97;
  border: 1px solid #0a254b;
  color: #ffffff;
  transition: 0.2s ease-in-out all;
}
