.globalcontainer{
  display: flex;
}
.avis1 {
  width: 210px;
  height: 300px;
  margin: 20px;
  font-size: 17px;
  border: solid 5px white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #ffff;
  padding: 40px 10px 10px 10px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.avisCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin-top: 50px;
}
.avis-imageCard1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px 50px 10px 40px;
  margin-bottom: 40px;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 70%;
  height: fit-content;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  flex-wrap: wrap;
  z-index: 1;
  align-content: space-around;
}
.avis-imageCard2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 0;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 30%;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  z-index: 2;
  margin-left: -15px;
  margin-top: 70px;
}
.avis-headerCard {
  display: flex;
  max-height: 300px;
  margin-right: 8%;
  margin-left: 8%;
  margin-bottom: -14px;
}
.avis-textContent1 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #0a354f;
  padding: 7px;
}
.avis-textContent2 {
  padding-bottom: 3px;
  border-bottom: 1.5px solid white;
  margin-bottom: 40px;
  font-size: 25px;
}
.background1 {
  background-color: #0a354f;
}
.background2 {
  background-color: #024658;
}
.background3 {
  background-color: #5ab6aa;
}
.block1 {
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  padding: 20px;
  width: 100%;
  margin-right: 20px;
}
.blocksContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 60%;
}
.callToAction {
  margin-top: 10px;
  padding: 10px;
  min-width: 80%;
  background-color: #0a354f;
  color: #ffff;
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.cardInformations {
  background-color: white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  /* border-radius: 5px; */
  /* width: 90%; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* margin-bottom: 200px; */
  margin-top: -20px;
  padding-bottom: 50px;
  min-height: 30vh;
}
.cardSubTitle {
  font-size: 18px;
  margin-left: 35px;
}
.cardTitleInformations {
  font-weight: normal;
  color: #5e91b6;
  text-align: center;
  line-height: 1.4em;
  padding-top: 20px;
  width: 90%;
    margin: 20px auto;
}
.containerInformations {
  padding: 0px;
  margin: 0px;
  width: 100vw;
  min-height: auto;
}
.containerGlobalFiches {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.fiches-blockList {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: disc;
  padding: 20px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.fiches-card {
  background-color: white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  padding-bottom: 50px;
  padding-left: 50px;
  min-height: 30vh;
}
.fiches-cardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: normal;
  color: #082a42;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
}
.fiches-container {
  padding: 0px;
  margin: 0px;
  width: 100vw;
  min-height: auto;
}
.fiches-container-main {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  min-width: 570px;
  border-radius: 7px;
  margin-bottom: 40px;
}
.fiches-listElem {
  cursor: pointer;
  font-size: 20px;
  text-align: start;
  color: #082a42;
  line-height: 1.8;
}
.fiches-picto {
  margin-right: 20px;
}
.footer {
  position: relative;
}
.headerCard {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-height: 300px;
}
.imageCard1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 100%;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  flex-wrap: wrap;
  align-content: space-around;
}
.imageCard2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 0;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 30%;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  z-index: 2;
  margin-left: -15px;
  margin-top: 70px;
}
.imageExperts {
  max-width: 100%;
  max-height: 240px;
}
.listElem {
  line-height: 2.3;
  cursor: pointer;
}
.pratiques-headerCard {
  display: flex;
  margin-right: 8%;
  margin-left: 8%;
}
.pratiques-imageCard1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px 50px 20px 40px;
  margin-bottom: 40px;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 70%;
  height: fit-content;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  flex-wrap: wrap;
  z-index: 1;
  align-content: space-around;
}
.pratiques-imageCard2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 0;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 60%;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  z-index: 2;
  margin-left: -15px;
  margin-top: 70px;
  height: fit-content;
}
.SubCardTitle {
  font-weight: normal;
  color: #0a354f;
  text-align: center;
  line-height: 1.4em;
  padding-top: 10px;
  margin-top: 5px;
  margin-bottom: 40px;
  font-size: 18px;
}
.textContent1 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #0a354f;
  padding: 7px;
}
.textContent2 {
  padding-bottom: 3px;
  border-bottom: 1.5px solid white;
  margin-bottom: 40px;
  font-size: 25px;
}
.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoPratique {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-content: space-around;
}
.videoStyle {
  width: 50%;
  height: 50%;
  border-radius: 7px;
  padding: 50px;
  margin-bottom: 40px;
  border: 1px solid #ffff;
  border-radius: 7px;
  width: 40%;
  background-color: #a0bacd;
  box-shadow: #c2c2c2 2px 2px 5px;
  flex-wrap: wrap;
  align-content: space-around;
}

@media (max-width: 1200px) {
  .avis-textContent1 {
    text-align: center;
    font-size: 15px;
    font-weight: 900;
    color: #0a354f;
    padding: 0px;
  }
  .blocksContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 60%;
    min-width: 410px;
  }
  .callToAction {
    margin-top: 10px;
    padding: 7px;
    min-width: 80%;
    background-color: #0a354f;
    color: #ffff;
    border-radius: 7px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }
  .card {
    background-color: white;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 150px;
    padding-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
    min-height: 30vh;
  }
  .cardTitle {
    font-weight: normal;
    color: #5e91b6;
    text-align: center;
    line-height: 1.4em;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  .containerGlobalFiches {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .fiches-container-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    min-width: 470px;
    border-radius: 7px;
    margin-bottom: 40px;
  }
  .fiches-blockList {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: disc;
    padding: 20px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }
  .fiches-cardTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: normal;
    color: #082a42;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
  }
  .fiches-listElem {
    cursor: pointer;
    font-size: 15px;
    text-align: start;
    color: #082a42;
    line-height: 1.8;
  }
  .headerCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: none;
    align-items: center;
  }
  .imageCard1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px 30px 20px 20px;
    margin-bottom: 40px;
    border: 1px solid #ffff;
    border-radius: 7px;
    width: 70%;
    background-color: #a0bacd;
    box-shadow: #c2c2c2 2px 2px 5px;
    flex-wrap: wrap;
    z-index: 1;
    align-content: space-around;
  }
  .SubCardTitle {
    font-weight: normal;
    color: #0a354f;
    text-align: center;
    line-height: 1.4em;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .textContent1 {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: #0a354f;
    padding: 4px;
  }
}

@media (max-width: 900px) {
  .fiches-container-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    min-width: 0px;
    border-radius: 7px;
    margin-bottom: 40px;
  }
  .pratiques-headerCard {
    display: flex;
    margin-right: 8%;
    margin-left: 8%;
    flex-wrap: wrap;
  }
  .pratiques-imageCard2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 0;
    border: 1px solid #ffff;
    border-radius: 7px;
    background-color: #a0bacd;
    box-shadow: #c2c2c2 2px 2px 5px;
    width: 100%;
    z-index: 0;
    margin-left: 0px;
    margin-top: 0px;
  }
  .pratiques-imageCard1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px 30px 20px 30px;
    margin-bottom: 30px;
    border: 1px solid #ffff;
    border-radius: 7px;
    width: 100%;
    height: fit-content;
    background-color: #a0bacd;
    box-shadow: #c2c2c2 2px 2px 5px;
    flex-wrap: wrap;
    z-index: 1;
    align-content: space-around;
  }
}
