body {
    background-color: rgb(255, 255, 2);
}

.containerProfile {
    min-height: 66.5vh;
    margin-top: 10vh;
    margin-bottom: 200px;
    margin-right: auto;
    margin-left: auto;
}

.containerProfileGlobal {
display: flex;
}

.container_logo {
    width: 20%;
    min-width: 50px;
    padding-right: 10%;
}

.container_card {
    background-color: rgb(255, 255, 255);
    min-height: 400px;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgb(0 0 0 / 20%) 2px 2px 1px;
    border-radius: 5px;
}

.container_card_header {
    background-color: #E8E6E9;
    width: 100%;
    min-height: 100px;
    text-align: center;
    font-size: 20px;
}

.card_header_items {
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.container_body_infos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
    padding-bottom: 50px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}

.container_body_password {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: -20px;
    padding-bottom: 50px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}

.item_50 {
    width: 40%;
}

.margin_ajust {
    margin-left: auto;
    margin-right: auto;
}

.margin_ajust_top {
    margin-top: 22px;
}


.item_100 {
    grid-column: span 2;
    width: 100%;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.trait {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #5E91B6;
    margin-top: 20px;
}

.modal_title {
    text-align: center;
}

.modal_rule {
    color: grey;
    font-size: 14px;
    font-weight: lighter;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.modal_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;

    /*gap: 20px;*/
}

.modal_item {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (max-width: 900px) {
    .container_body_infos {
        flex-direction: column;
    }

    .item_50 {
        width: 90%;
    }

    .container_body_password {
        flex-direction: column;
    }
}
